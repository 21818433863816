import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import { HomePage } from './main';
import { authRoutes } from './auth';
import { admindashboardRoutes } from './admin-dashboard';
import { componentsRoutes } from './components';
import ContactUs from 'src/pages/contactUs';
import AboutUsPage from 'src/sections/about/view/about-view';


export default function Router() {

  return useRoutes([
    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    {
      path: '/contact-us',
      element: (<ContactUs />),
    },

    {
      path: '/about-us',
      element: (<AboutUsPage />),
    },

    ...authRoutes,
    ...admindashboardRoutes,
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
