import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const supportApi = createApi({
    reducerPath: "support",
    baseQuery: createCustomFetchBaseQuery(),
    endpoints: (builder) => ({
        // GET ALL CONTACT MESSAGES
        getAllContactMessages: builder.query({
            query: (pageno) => `api/admin/contact?pageno=${pageno}`,
            transformResponse: (res) => res
        }),

    }),
});

export const {
    useGetAllContactMessagesQuery
} = supportApi;