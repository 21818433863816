import Iconify from 'src/components/iconify';

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'About Us',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: '/about-us',
  },
  {
    title: 'Contact Us',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: '/contact-us',
  },
];
