import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { useSelector } from 'react-redux';
//
import { useAuthContext } from '../hooks';
import { useMockedUser } from 'src/hooks/use-mocked-user';


// ----------------------------------------------------------------------

const loginPaths = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();
  const { user } = useMockedUser();
  const { method } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!user?.email) {
      const loginPath = loginPaths['jwt'];
      const href = `${loginPath}`;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [user, method, router]);


  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
