import { Helmet } from 'react-helmet-async';
import ContactUsForm from 'src/sections/contact/ContactUsForm';



export default function ContactUs() {
    return (
        <>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>

            <ContactUsForm />
        </>
    );
}
