import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";


export const TransferApi = createApi({
    reducerPath: "transfer",
    tagTypes: ['Transfer'],
    baseQuery: createCustomFetchBaseQuery(),
    endpoints: (builder) => ({
        getAllTransactions: builder.query({
            query: (data) => ({
                url: `api/admin/transactions?pageno=${data.pageno}&search=${data.search}`,
                method: "GET",
                transformResponse: (res) => ({data:res.data,total_pages:res.total_pages}),
            }),
            providesTags: ['Transfer'],
        }),

        toggleTransferStatus: builder.mutation({
            query: (data) => ({
                url: `api/admin/transaction/${data.id}?status=${data.newStatus}`,
                method: "PUT",
            }),
            invalidatesTags: ['Transfer']
        }),
    }),
});

export const {
   useGetAllTransactionsQuery,
   useToggleTransferStatusMutation,
} = TransferApi;