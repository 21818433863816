import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import AddExchangeRateModal from 'src/components/modals/AddExchangeRateModal';
import { useUpdateCurrencyStatusMutation } from 'src/store/Reducer/currency';
import { enqueueSnackbar } from 'notistack';

export default function UserTableRow({ row, selected, setOpen, setIsEdit, setSelectedCurrency, counter, refetch }) {
    const { from, status, to, value } = row;

    const [updateCurrencyStatus] = useUpdateCurrencyStatusMutation();
    const [currencyStatus, setCurrencyStatus] = useState(status);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const storedStatus = localStorage.getItem(`currencyStatus_${row._id}`);
        if (storedStatus !== null) {
            setCurrencyStatus(JSON.parse(storedStatus));
        }
    }, [row._id]);

    const handleUpdateCurrencyStatus = async (id) => {
        const newStatus = !currencyStatus;
        try {
            await updateCurrencyStatus(id);
            setCurrencyStatus(newStatus);
            enqueueSnackbar('Status updated successfully!');
            // Update the status in the row state
            localStorage.setItem(`currencyStatus_${id}`, JSON.stringify(newStatus));
            // Refetch the table data
            refetch();
        } catch (error) {
            console.error('Failed to update currency status!', error);
            enqueueSnackbar('Failed to update currency status', { variant: 'error' });
        }
    }

    const handleEdit = (currency) => {
        setSelectedCurrency(currency);
        setIsEdit(true);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setIsEdit(false);
        setSelectedCurrency(null);
    }

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell>{counter}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{from}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{to}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{value}</TableCell>
                <TableCell>
                    <Label variant="soft" color={(currencyStatus === true && 'success') || (currencyStatus === false && 'error') || 'warning'}>
                        <Button variant='text' size='small' onClick={() => handleUpdateCurrencyStatus(row?._id)}>
                            {currencyStatus ? "Active" : 'Inactive'}
                        </Button>
                    </Label>
                </TableCell>
                <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton onClick={() => handleEdit(row)}>
                        <Iconify icon="solar:pen-bold" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <AddExchangeRateModal 
                open={openModal} 
                handleClose={handleCloseModal} 
                setOpen={setOpenModal} 
                isEdit={true} 
                selectedCurrency={row} 
                refetch={refetch} 
                reset={() => {}} 
            />
        </>
    );
}

UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
    counter: PropTypes.number,
    setOpen: PropTypes.func.isRequired,
    setIsEdit: PropTypes.func.isRequired,
    setSelectedCurrency: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
};
