import { Card, CircularProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import Button from '@mui/material/Button';
import { TableHeadCustom, TableNoData, useTable } from 'src/components/table'
import UserTableRow from '../UserTableRow'
import AddExchangeRateModal from 'src/components/modals/AddExchangeRateModal'
import { Box, Container } from '@mui/system'
import { useGetAllCurrenciesQuery } from 'src/store/Reducer/currency'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'

const TABLE_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'from', label: 'From' },
  { id: 'to', label: 'To', width: 180 },
  { id: 'value', label: 'Value', width: 220 },
  { id: 'status', label: 'Status', width: 220 },
  { id: 'action', label: 'Action', width: 220 },
];

const ExchangeRatePage = () => {
  const [page, setPage] = useState(1);

  // Fetch currencies and refetch on page change
  const { data, refetch, isLoading, status } = useGetAllCurrenciesQuery(page);
  const allCurrencies = data?.body;

  const table = useTable();

  const [isEdit, setIsEdit] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChange = (event, value) => {
    setPage(value);
    refetch();
  };

  const handleOpen = () => {
    setIsEdit(false);
    setSelectedCurrency(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setSelectedCurrency(null);
  };

  // Resetting state to ensure updated values are shown after closing the modal
  const handleRefetch = () => {
    refetch();
    setOpen(false);
    setIsEdit(false);
    setSelectedCurrency(null);
  };

  const notFound = !allCurrencies?.length;
  const startIndex = (page - 1) * 10;

  return (
    <>
      <Container>
        {isLoading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
            <Typography>
              Loading Currencies, Please wait...
            </Typography>
          </Box>
        ) : (
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '20px 0' }}>
              <CustomBreadcrumbs
                heading="Exchange Rates"
                links={[
                  {},
                ]}
              />
              <Button
                variant="contained"
                onClick={handleOpen}
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Add New Currency
              </Button>
            </Box>
            <AddExchangeRateModal
              open={open}
              handleClose={handleClose}
              setOpen={setOpen}
              isEdit={isEdit}
              selectedCurrency={selectedCurrency}
              refetch={handleRefetch} // Using the new handleRefetch function
            />
            <Card>
              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={data?.total_pages}
                      numSelected={table.selected?.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          allCurrencies?.map((row) => row.id)
                        )
                      }
                    />

                    {status === 'pending' ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={12} sx={{ textAlign: 'center', height: "28rem" }}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                      {allCurrencies?.length > 0 ? allCurrencies?.map((row, idx) => (
                        <UserTableRow
                          key={row._id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          setIsEdit={setIsEdit}
                          setSelectedCurrency={setSelectedCurrency}
                          setOpen={setOpen}
                          counter={startIndex + idx + 1}
                          refetch={refetch} // Pass refetch here
                        />
                      )) :
                        <TableNoData notFound={notFound} />
                      }
                    </TableBody>
                    
                    )}
                  </Table>
                </Scrollbar>
              </TableContainer>
              {/* Pagination */}
              <Box display={'flex'} justifyContent={'center'} mt={'10px'} mb={'10px'}>
                <Pagination count={data?.total_pages} page={page} onChange={handleChange} />
              </Box>
            </Card>
          </div>
        )}
      </Container>
    </>
  )
}

export default ExchangeRatePage;
